(function () {
    angular.module('informaApp')
        .service('HorizontalbarGanttChartHelper', HorizontalbarGanttChartHelper);

    function HorizontalbarGanttChartHelper(d3, PhasesBarHelper, ChartElementsHelper, LazyLoadingService) {
        return function (container, data, opitons = {}) {
            function getTooltipText(d) {
                return '<span class=\'main-title\'>' + (d.mainTitle) + '</span>' +
                    '<span> (' + (d.subTitle) + ')</span><br>' +
                    '<span class=\'program-result-title\' style=\'color:' + d.statusColor + '\'>' + (d.programResult) + '</span><br>' +
                    '<span class=\'duration\'>' + d.durationToString(d.source.total) + '</span>';
            }

            function getTooltipTextForPhase(d) {
                return '<span class=\'main-title\'>' + d.name + '</span><br>' +
                    '<span class=\'duration\'>' + d.durationToString(d.duration) + '</span>';
            }

            const tooltipContainer = d3.select('body')
                .append('div')
                .attr('class', 'horizontalbar-gantt-toolTip');

            const chartOptions = {
                marginLeft: 0,
                marginRight: 20,
                barHeight: 15,
                barPadding: 5,
                marginBottom: 20,
                labelWidth: 150
            };

            const width = (container.clientWidth || container.offsetWidth) - 20;
            const height = (data.length * chartOptions.barHeight + chartOptions.marginBottom) + (data.length * chartOptions.barPadding);

            const maxDurationInYears = d3.max(data, (barData => barData.daysToYears(barData.source.total)));

            d3.select(container).selectAll('.horizontal-chart-container').remove();

            const divContainer = d3.select(container)
                .append('div')
                .attr('class', 'horizontal-chart-container')
                .style('width', width)
                .style('height', '100%')
                .style('overflow-y', 'auto');

            const svg = divContainer
                .append('svg')
                .style('width', width)
                .style('height', height)
                .attr('class', 'horizontal-gantt');

            const defs = svg.append('defs');

            svg.style.height = container.style.height;

            if (!data.length) {
                svg.append('text')
                    .attr('dy', '1em')
                    .attr('dx', '1em')
                    .text('No Data');
            }

            const scale = d3.scale.linear()
                .domain([0, maxDurationInYears])
                .range([0, width - chartOptions.marginLeft - chartOptions.marginRight - chartOptions.labelWidth]);

            const xAxis = d3.svg.axis()
                .scale(scale)
                .tickSize(-height + chartOptions.barPadding + chartOptions.marginBottom)
                .orient('bottom');

            LazyLoadingService.lazyDraw(divContainer.node(), data, createItem, getMarginYForBar, getIndexByY, chartOptions.barHeight);

            function createItem(barData, index) {
                const bar = createBar(svg, chartOptions.marginLeft, getMarginYForBar(index));

                const labelY = chartOptions.barHeight / 2;
                const label = createLeftLabel(bar, barData.mainTitle, labelY, chartOptions.labelWidth, barData.mainTitleLink, barData.onMainTitleClick);

                label
                    .on('mousemove', function () {
                        this.style.textDecoration = 'underline';
                        showTooltip(barData, getTooltipText);
                    })
                    .on('mouseout', function () {
                        this.style.textDecoration = null;
                        hideTooltip();
                    });

                createPhasesProgressBar(bar,
                    barData,
                    chartOptions.labelWidth,
                    scale(barData.daysToYears(barData.source.total)),
                    chartOptions.barHeight,
                    barData.source,
                    !barData.hasInvalidData,
                    defs,
                    (data) => {
                        showTooltip(data, getTooltipTextForPhase);
                    },
                    () => {
                        hideTooltip()
                    },
                    PhasesBarHelper
                );

                createStatusIcon(bar,
                    barData.status,
                    scale(barData.daysToYears(barData.source.total)) + chartOptions.labelWidth + 5,
                    chartOptions.barHeight / 2 + 1,
                    ChartElementsHelper
                );

                return {
                    element: bar,
                    remove: () => {
                        bar.remove();
                    }
                }
            }

            function getMarginYForBar(index) {
                return index * (chartOptions.barHeight + chartOptions.barPadding) + chartOptions.barPadding;
            }

            function getIndexByY(y) {
                return Math.round((y - chartOptions.barPadding ) / (chartOptions.barHeight + chartOptions.barPadding));
            }

            function showTooltip(d, fn) {
                tooltipContainer.style('left', d3.event.pageX + 10 + 'px');
                tooltipContainer.style('top', d3.event.pageY - 25 + 'px');
                tooltipContainer.style('display', 'inline-block');
                tooltipContainer.html(fn(d));
                document.body.style.cursor = 'pointer';
            }

            function hideTooltip() {
                tooltipContainer.style('display', 'none');
                document.body.style.cursor = 'default';
            }

            svg.insert('g', ':first-child')
                .attr('class', 'axisHorizontal')
                .attr('transform', 'translate(' + (chartOptions.marginLeft + chartOptions.labelWidth) + ',' + (height - chartOptions.marginBottom) + ')')
                .call(xAxis);

            if (opitons.removeOddTicks) {
                removeAllOddElements(d3, svg.selectAll('.axisHorizontal .tick'));
            }

            this.destroySVG = function () {
                svg.remove();
                tooltipContainer.remove();
            };
        };
    }

    function createBar(container, x, y) {
        return container
            .append('g')
            .attr('class', 'bar')
            .attr('cx', 0)
            .attr('transform', `translate(${x}, ${y})`);
    }

    function createPhasesProgressBar(container, data, x, width, height, source, hideNoDataBar, defs, onMove, onMouseOut, PhasesBarHelper) {
        return new PhasesBarHelper.createPhasesProgressBar({
            container,
            x,
            y: 0,
            width,
            defsElement: defs,
            height,
            source,
            phaseMouseMove: onMove,
            phaseMouseOut: onMouseOut,
            hideNoDataBar,
            durationToString: data.durationToString
        });
    }

    function createStatusIcon(container, status, x, y, ChartElementsHelper) {
        container.append('text')
            .text(ChartElementsHelper.getIconByStatus(status))
            .attr('class', 'glyphicon')
            .attr('fill', ChartElementsHelper.getStatusColor(status))
            .attr('font-size', '12px')
            .attr('dy', '0.35em')
            .attr('y', y)
            .attr('x', x);
    }

    function createLeftLabel(container, text, y, width, link, onClick) {
        const label = container.append('text')
            .attr('class', 'label')
            .attr('y', y)
            .attr('dy', '.35em') //vertical align middle
            .attr('width', width)
            .text(text)
            .on('click', getLabelClickEvent(link, onClick));

        fitLabelContent(label, width);

        return label;
    }

    function fitLabelContent(label, labelWidth) {
        const node = label.node();
        const text = node.textContent;

        const step = 5;

        let textLength = text.length;

        while (node.getComputedTextLength() >= labelWidth) {
            node.textContent = text.substr(0, textLength) + '...';
            textLength -= step;
        }
    }

    function removeAllOddElements(d3, elements) {
        elements.each(function (data, index) {
            if (index % 2 === 1) {
                d3.select(this).remove();
            }
        });
    }

    function getLabelClickEvent(link, onClick) {
        return () => {
            const win = window.open(link, '_blank');

            onClick && onClick();

            if (win) {
                win.focus();
            }
        };
    }
})();

